import TextTexture from 'core/three/TextTexture/TextTexture';
import * as THREE from 'three';
import { MarkerType } from '../../type';
import ThumbnailModel from './ThumbnailModel';
import { RenderFrame } from 'core/types';

export default class AvatarModel extends ThumbnailModel {
    public height = 1.75;
    public width = 0.4;
    public lodDistance = 5;
    public namecard = new THREE.Mesh<THREE.PlaneGeometry, THREE.MeshBasicMaterial>();
    protected animation = { walk: 'Walking', idle: 'Idle' };
    protected currentAnimaion = '';
    private prevPosition = new THREE.Vector3();
    async init(info: { fileUrl: string; name: string; id?: string }) {
        this.id = info?.id;
        await super.init({
            ...info,
            type: MarkerType.MODEL,
            fileUrl: info.fileUrl,
            autoRotate: false,
            rotateControl: false,
            price: null,
            imageUrls: [],
            autoPlayAnimation: false,
            name: info.name,
            description: '',
            isVertical: false,
            followCamera: false,
            linkName: '',
            linkUrl: '',
            openType: 'iframe',
            unclickable: false,
            searchable: false,
            visible: true,
            thumbnailUrl: '',
            defaultWidth: 0,
            videoUrl: '',
        });
        this.lodDistance = 10;
        this.setLodDistance();
    }
    set position(pos: THREE.Vector3) {
        this.object.position.set(pos.x, pos.y, pos.z);
    }
    get position() {
        return this.object.position;
    }
    public walk() {
        const walk = this.animation.walk;
        if (this.currentAnimaion !== walk) {
            if (this.playModelAnimation(walk)) {
                this.currentAnimaion = walk;
            }
        }
    }
    public idle() {
        const idle = this.animation.idle;
        if (this.currentAnimaion !== idle) {
            if (this.playModelAnimation(idle)) {
                this.currentAnimaion = idle;
            }
        }
    }
    public update(renderFrame: RenderFrame): void {
        super.update(renderFrame);
        this.namecardLookAt(renderFrame.cameraPosition);
        if (renderFrame.environment === 'viewer') {
            const movement = this.object.position.clone().sub(this.prevPosition);
            this.updateDirection(movement);
            if (movement.length() > 0.01) this.walk();
            else this.idle();
            this.prevPosition.copy(this.object.position);
        }
    }

    protected updateDirection(direction: THREE.Vector3) {
        this.object.lookAt(this.object.position.clone().add(direction.clone().setY(0) /** 避免位置升降造成旋轉 */));
    }


    public createNamecard(name = this.name, isHost = false) {
        const textTexture = new TextTexture(
            {
                label: name,
                padding: 80,
                fontColor: '#ffffff',
                fontSize: 72,
                fontStyle: 'normal',
                fontWeight: 'bold',
                alignment: 'center',
                lineSpace: 0.2,
            },
            {
                nameTemplateStyle: true,
                iconStyle: isHost,
                fillStyle: isHost ? '#ED0973B2' : undefined,
            },
        );

        textTexture.needsUpdate = true;

        const material = new THREE.MeshBasicMaterial({ map: textTexture, transparent: true });
        const geometry = new THREE.PlaneGeometry(1, 1);
        this.namecard.material = material;
        this.namecard.geometry = geometry;
        this.namecard.scale.set(0.5, 0.2, 0.2);
        this.namecard.position.y = 2.0;
        this.object.add(this.namecard);
    }

    public setLodDistance(distance = this.lodDistance) {
        this.lodDistance = distance;
        this.object.levels.length = 0;
        if (this.glb && this.thumbnail) {
            this.object.addLevel(this.glb);
            this.object.addLevel(this.thumbnail, distance);
        } else if (this.thumbnail) {
            this.object.addLevel(this.thumbnail);
        }
    }

    public updateRaycastMesh() {
        if (!this.glb) {
            super.updateRaycastMesh();
            return;
        }
        this.raycastMesh.geometry = new THREE.BoxGeometry(this.width, this.height, this.width);
        this.raycastMesh.position.y = this.height / 3;
    }

    public namecardLookAt(pos: THREE.Vector3) {
        const n = new THREE.Vector3().subVectors(pos, this.position).normalize();
        n.y = 0;
        const mat4 = new THREE.Matrix4().lookAt(n, new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 1, 0));
        this.namecard.setRotationFromMatrix(mat4);
    }

    public destroy() {
        this.namecard?.geometry.dispose();
        this.namecard?.material.dispose();
        super.destroy();
    }
}
