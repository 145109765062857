import * as THREE from 'three';
import { MarkerType } from 'core/three/object/type';
import Media from 'core/three/object/media/Media';
import { IMusic } from 'core/types/media';
import Thumbnail from '../utils/Thumbnail';
import { CurvePlaneGeometry } from 'core/utils/CurvePlaneGeometry';
export default class Music extends Media {
    private iconMesh: THREE.Mesh<CurvePlaneGeometry, THREE.MeshBasicMaterial>;
    private autoplay = false;
    private volume = 0;
    private curve: number;

    private thumbnail: Thumbnail;
    private oldThumbnailUrl: string = '';

    constructor() {
        super(MarkerType.MUSIC);
        // create Image Mesh
        const imgGeometry = new CurvePlaneGeometry(1.0, 1.0);
        const imgMaterial = new THREE.MeshBasicMaterial({ map: null, side: THREE.DoubleSide, transparent: true });
        this.iconMesh = new THREE.Mesh(imgGeometry, imgMaterial);
        this.object.add(this.iconMesh);
    }

    public get json() {
        return {
            ...super['json'],
            autoplay: this.autoplay,
            volume: this.volume,
            curve: this.curve,
        };
    }

    public showLevel() {
        if (!this.lodEnabled) {
            return;
        }
        const level = this.curLevel;
        if (this.prevLevel !== level) {
            this.prevLevel = level;
            switch (level) {
                case 0:
                    this.iconMesh.visible = true;
                    this.showBoundaryBox(false);
                    break;
                case 1:
                    this.iconMesh.visible = false;
                    this.showBoundaryBox(true);
                    break;
                case 2:
                    this.iconMesh.visible = false;
                    this.showBoundaryBox(false);
                    break;
            }
        }
    }

    public async setJson(data: IMusic) {
        super.setJson(data);
        this.autoplay = data.autoplay;
        this.volume = data.volume;
        this.curve = data.curve;
        super.markStatusUpdate();

        await this.updateIcon();
    }

    public async init(data: IMusic) {
        super.init(data);
        this.autoplay = data.autoplay || false;
        this.volume = data.volume || 0;
        this.curve = data.curve || 0;

        this.thumbnail = new Thumbnail(this.thumbnailUrl);
        await this.thumbnail.init();
        await this.updateIcon();
    }

    async updateIcon() {
        this.iconMesh.geometry.curvePlane(this.curve);
        if (this.oldThumbnailUrl === this.thumbnailUrl) return;

        await this.thumbnail.update(this.thumbnailUrl);
        const texture = this.thumbnail.texture;
        const ratio = this.thumbnail.ratio;

        const oldGeometry = (this.iconMesh as THREE.Mesh).geometry;

        this.iconMesh.material.map = texture;
        this.iconMesh.geometry = new CurvePlaneGeometry(1, ratio, this.curve);

        if (oldGeometry) oldGeometry.dispose();

        this.oldThumbnailUrl = this.thumbnailUrl;
    }

    updateQuality(quality: 'small' | 'medium' | 'standard') {
        this.quality = quality;
        this.updateIcon();
    }

    public updateAnimation(deltaSec: number): void {
        super.updateAnimation(deltaSec)
        this.thumbnail.updateFrame()
    }

    public destroy() {
        super.destroy();

        //dispose geometry
        if (this.iconMesh.geometry) {
            this.iconMesh.geometry.dispose();
            this.iconMesh.geometry = null;
        }
        if (this.iconMesh.material) {
            //dispose texture
            if ((this.iconMesh.material as THREE.MeshBasicMaterial).map) {
                (this.iconMesh.material as THREE.MeshBasicMaterial).map.dispose();
                (this.iconMesh.material as THREE.MeshBasicMaterial).map = null;
            }

            //dispose material
            (this.iconMesh.material as THREE.Material).dispose();
            this.iconMesh.material = null;
        }
        this.thumbnail?.dispose();
    }
}
