import * as THREE from 'three';
import { MarkerType } from 'core/three/object/type';
// import Global from 'core/three/base/Global';
import Media from 'core/three/object/media/Media';
import { IPortal } from 'core/types/media';
import Thumbnail from '../utils/Thumbnail';
import { CurvePlaneGeometry } from 'core/utils/CurvePlaneGeometry';

export default class Portal extends Media {
    public mesh: THREE.Mesh<CurvePlaneGeometry, THREE.MeshBasicMaterial>;
    public openType: 'redirect' | 'window' | 'iframe' = 'redirect';
    private oldThumbnailUrl: string = '';
    private thumbnail: Thumbnail;
    private curve: number;

    constructor() {
        super(MarkerType.PORTAL);
    }

    public get json() {
        return {
            ...super['json'],
            curve: this.curve,
        };
    }

    public async setJson(value: IPortal) {
        super.setJson(value);
        this.curve = value.curve;
        super.markStatusUpdate();
        await this.updateThumbnail(this.thumbnailUrl);
    }

    public async init(data: IPortal) {
        super.init(data);
        this.curve = data.curve ?? 0;
        await this.load(this.thumbnailUrl, this.defaultWidth);
    }

    private async load(srcUrl: string, defaultWidth: number): Promise<void> {
        this.thumbnail = new Thumbnail(srcUrl);
        await this.thumbnail.init();
        const texture = this.thumbnail.texture;
        const ratio = this.thumbnail.ratio;

        const geometry = new CurvePlaneGeometry(1.0 * defaultWidth, ratio * defaultWidth);
        const material = new THREE.MeshBasicMaterial({
            map: texture,
            side: THREE.DoubleSide,
            transparent: true,
            combine: 0,
        });
        this.mesh = new THREE.Mesh(geometry, material);
        this.object.add(this.mesh);
        this.updateRaycastMesh(geometry);
        this.updateBoundaryBox(1.0 * defaultWidth, ratio * defaultWidth, 0.001);

        this.oldThumbnailUrl = this.thumbnailUrl;
    }

    private async updateThumbnail(srcUrl: string) {
        this.mesh.geometry.curvePlane(this.curve);
        if (this.oldThumbnailUrl === this.thumbnailUrl) return;

        await this.thumbnail.update(srcUrl);
        const texture = this.thumbnail.texture;
        const ratio = this.thumbnail.ratio;

        const oldGeometry = this.mesh.geometry;

        const geometry = new CurvePlaneGeometry(1.0 * this.defaultWidth, ratio * this.defaultWidth, this.curve);
        this.mesh.geometry = geometry;
        this.mesh.material.map = texture;
        this.updateRaycastMesh(geometry);
        this.updateBoundaryBox(1.0 * this.defaultWidth, ratio * this.defaultWidth, 0.001);

        this.dispose(oldGeometry);

        this.oldThumbnailUrl = this.thumbnailUrl;
    }

    public onMouseEnter() {}
    public onMouseLeave() {}

    public showLevel() {
        if (!this.lodEnabled) {
            return;
        }
        const level = this.curLevel;
        if (this.prevLevel !== level) {
            this.prevLevel = level;
            switch (level) {
                case 0:
                    this.mesh.visible = true;
                    this.showBoundaryBox(false);
                    break;
                case 1:
                    this.mesh.visible = false;
                    this.showBoundaryBox(true);
                    break;
                case 2:
                    this.mesh.visible = false;
                    this.showBoundaryBox(false);
                    break;
            }
        }
    }

    public updateAnimation(deltaSec: number): void {
        super.updateAnimation(deltaSec);
        this.thumbnail.updateFrame();
    }

    private dispose(geometry?: THREE.PlaneGeometry, material?: THREE.MeshBasicMaterial, texture?) {
        //dispose geometry
        if (geometry) {
            geometry.dispose();
            geometry = null;
        }

        if (material) {
            //dispose texture
            if (material.map) {
                material.map.dispose();
                material.map = null;
            }

            //dispose material
            material.dispose();
            material = null;
        }

        if (texture) {
            texture.dispose();
        }
    }

    public destroy() {
        super.destroy();

        this.dispose(this.mesh.geometry, this.mesh.material);
        this.thumbnail?.dispose();
    }
}
