import * as THREE from 'three';
import { EObjectType } from 'core/three/object/type';
import Object3D from 'core/three/object/CustomObject3D';
import { SceneHotspot } from 'core/types/sceneHotspots';

export default class Hotspot extends Object3D {
    static defaultOpcity: number = 0.4;
    public id: string = ''
    private mouseIsEnter: boolean = false;
    private geometry: THREE.RingGeometry;
    private material: THREE.MeshBasicMaterial;
    private mesh: THREE.Mesh;

    constructor() {
        super(EObjectType.HOTSPOT);
    }

    public set visible(value: boolean) {
        this.mesh.visible = value;
    }

    public createHotspot(data: SceneHotspot) {
        const { position: pos, id } = data;
        this.material = new THREE.MeshBasicMaterial({
            map: null,
            side: THREE.DoubleSide,
            transparent: true,
            opacity: Hotspot.defaultOpcity,
            depthTest: true,
            color: 0xd0d0d0,
        });
        this.id = id
        this.geometry = new THREE.RingGeometry(0.1, 0.15, 30, 1, 1);
        this.mesh = new THREE.Mesh(this.geometry, this.material);

        this.mesh.quaternion.setFromAxisAngle(new THREE.Vector3(1, 0, 0), -Math.PI / 2);
        this.object.add(this.mesh);
        this.object.position.set(pos.x, pos.y, pos.z);

        const geometry = new THREE.SphereGeometry(0.2, 32, 32);
        this.updateRaycastMesh(geometry);
    }

    public onMouseEnter(): void {
        if (this.mouseIsEnter === false || this.mouseIsEnter == null) {
            this.mouseIsEnter = true;
            this.material.opacity = 1;
        }
    }
    public onMouseLeave(): void {
        if (this.mouseIsEnter === true) {
            this.mouseIsEnter = false;
            this.material.opacity = Hotspot.defaultOpcity;
        }
    }

    onMouseMove(): void {}
    onClick() {}
    public onMouseUp(): void {}
    public onMouseDown(): void {}

    public destroy() {
        super.destroy();
        if (this.geometry) {
            this.geometry.dispose();
            this.geometry = null;
        }
        if (this.material) {
            this.material.dispose();
            this.material = null;
        }
    }
}
